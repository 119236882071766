.deployform {
  position: fixed;
  bottom: 40%;
  right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #008bd0;
  cursor: pointer;
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  transition: all 0.35s cubic-bezier(0.91, -0.28, 0.06, 1.3), background-color 0.25s ease;
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);

}

.deployform:hover {
  background-color: #008bd0;
}

.deployform.open {
  width: 280px;
  height: max-content;
  padding-bottom: 20px;
  border-radius: 15px;
  background-color: #fff;
  cursor: default;
  top: 60px;
  z-index: 99;
}

.deployform.open .icon {
  opacity: 0;
  transition-delay: 0.01s;
}

.deployform span {
  position: absolute;
  color: rgb(255, 255, 255);
  background: linear-gradient(135deg, #008bd0 0%, rgb(0, 204, 255) 100%);
  padding: 25px;
  width: 280px;
  top: -20px;
  font-size: 18px;
  font-weight: 600;
  z-index: -2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.deployform img {
  width: 25px;
  margin-right: 20px;
}

.deployform.open form {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
}

.deployform .icon {
  width: 140px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  left: -68px;
  top: -50px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 0.1s ease;
  transition-delay: 0.2s;
  color: #fff;
  font-size: 18px;
  padding: 4px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-appearance: none;
}

.deployform .cancel {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 15px;
  cursor: pointer;
  padding: 6px 10px;
  color: #0c224e;
  z-index: -1;
}

.deployform form {
  margin-top: 40px;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.01s;
}

.deployform form input {
  width: 236px;
  height: 45px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 0 10px;
  border: 1px solid #e3e3e3;
  background-color: #f1f1f1;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.deployform form input:hover,
.deployform form input:focus {
  border: 1px solid #d7d7d7;
  outline: 0;
}

.deployform form textarea {
  width: 236px;
  min-height: 80px;
  margin-left: 20px;
  margin-top: 10px;
  border: 2px solid #e3e3e3;
  background-color: #f1f1f1;
  border-radius: 2px;
  resize: none;
  padding: 10px 10px;
  font-family: inherit;
  transition: all 0.2s ease;
}

.deployform form textarea:hover,
.deployform form textarea:focus {
  border: 2px solid #d7d7d7;
  outline: 0;
}

.deployform form button {
  width: 240px;
  height: 45px;
  margin-left: 20px;
  margin-top: 10px;
  border: none;
  background-color: #008bd0;
  border-radius: 2px;
  color: #fff;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.deployform form button a {
  color: #fff;

  
}

.deployform form .newPhone input{
  width: 206px;
  height: 45px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 10px 15px;
  border: 1px solid #e3e3e3;
  background-color: #f1f1f1;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.deployform form .newPhone input:focus {
  border: 1px solid #e3e3e3;
  outline: 5px solid white;
}

.newPhone.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 2px solid #e3e3e3;
  border-radius: 3px 0 0 3px;

}

.newPhone.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
  background-color: white;
  width: 236px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.deployform form .newPhone {
  width: 236px;
  height: 45px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 0 10px;
  border: 1px solid #e3e3e3;
  background-color: #f1f1f1;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.react-tel-input.contact-phone.invalid {
  width: 100% !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .deployform .icon {
    width: 125px;
    -webkit-width: 125px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    left: -60px;
    top: -20px;
  }

  .deployform {
    width: 20px;
    height: 20px;
    /* Apple compatible command for smoother animations */
  }

  .deployform .cancel {
    font-size: 15px;
  }
}

#phone_hidden {
  display: none;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}

#name_hidden {
  display: none;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}

#email_hidden {
  display: none;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}


.contact-phone{
  max-width: max-content;
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #f1f1f1 !important;
  border-bottom: 1px solid #f1f1f1 !important;;
  background-color: #f1f1f1 !important;
  border-left-color: #e3e3e3 !important;
  width: max-content;
 overflow-x: hidden;
 max-width: 300px;
}

.react-tel-input .flag-dropdown.invalid-number {
  border-color: #ffffff !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
  overflow-x: hidden;
}
div#deployform .react-tel-input .form-control{
  width: max-content !important;

}

.text-danger{
  font-size: 12px;
  font-weight: 600;
}